<template>
   <div style="width:100%;height:100%;background-color:#F2EEEE;">
      <div style="width:100%;height:100%;background-color:#F2EEEE;"> 

         <v-container 
            style="max-width:2600px;"
            class="pa-0">

            <!-- filter options -->
            <v-row class="ma-0 pa-0">
               <v-col cols="12" class="pt-2 pb-1">
                  <v-card flat style="background-color:transparent;">
                     <v-card-title class="pa-0">
                        <v-menu 
                           v-model="menuDatePicker" 
                           :close-on-content-click="false">
                           <template v-slot:activator="{ on, attrs }">
                              <div
                                 v-bind="attrs"
                                 class="mt-2 py-1 px-4 text-center"
                                 style="background-color:white;border-radius:6px;font-size:16px;min-width:180px;"
                                 v-on="on"
                              >
                                 <div style="display:inline-block;" class="mr-4">
                                 <v-icon style="font-size:24px;" color="#555555">
                                    mdi-calendar-check
                                 </v-icon>
                                 </div>
                                 <div style="display:inline-block;">
                                 {{ $moment(range.start).format("DD/MM/YYYY") }}
                                 <template
                                    v-if="
                                       $moment(range.start).format('DDMMYY') !=
                                       $moment(range.end).format('DDMMYY')
                                    "
                                 >
                                    - {{ $moment(range.end).format("DD/MM/YYYY") }}
                                 </template>
                                 </div>
                              </div>
                           </template>
                           <v-card flat>
                              <v-card-text class="pa-2">
                                 <div>
                                    <div style="display:inline-block;vertical-align:top;">
                                       <vc-date-picker
                                          v-model="range"
                                          ref="datepicker"
                                          is-range
                                          :model-config="modelConfig"
                                       />
                                    </div>
                                 <div v-if="$vuetify.breakpoint.mdAndUp"
                                    class="py-0 pl-2 pr-0"
                                    style="display:inline-block;vertical-align:top;"
                                 >
                                    <v-list dense style="min-width:140px;font-size:16px;">
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('today')"
                                       >
                                       <v-list-item-content>
                                          Hôm nay
                                       </v-list-item-content>
                                       </v-list-item>
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('yesterday')"
                                       >
                                       <v-list-item-content>
                                          Hôm qua
                                       </v-list-item-content>
                                       </v-list-item>
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('this-week')"
                                       >
                                       <v-list-item-content>
                                          Tuần này
                                       </v-list-item-content>
                                       </v-list-item>
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('last-week')"
                                       >
                                       <v-list-item-content>
                                          Tuần trước
                                       </v-list-item-content>
                                       </v-list-item>
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('this-month')"
                                       >
                                       <v-list-item-content>
                                          Tháng này
                                       </v-list-item-content>
                                       </v-list-item>
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('last-month')"
                                       >
                                       <v-list-item-content>
                                          Tháng trước
                                       </v-list-item-content>
                                       </v-list-item>
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('last-7-days')"
                                       >
                                       <v-list-item-content>
                                          7 ngày qua
                                       </v-list-item-content>
                                       </v-list-item>
                                       <v-list-item
                                       link
                                       style="min-width:100px;"
                                       @click="pickdate('last-30-days')"
                                       >
                                       <v-list-item-content>
                                          30 ngày qua
                                       </v-list-item-content>
                                       </v-list-item>
                                    </v-list>
                                 </div>
                                 </div>
                              </v-card-text>
                              <v-card-actions>
                                 <v-spacer />
                                 <v-btn text color="black" @click="menuDatePicker = false">
                                 OK
                                 </v-btn>
                              </v-card-actions>
                           </v-card>
                        </v-menu>


                        <!-- customer filter -->
                        <user-selector
                           class="mt-2"
                           :class="{'ml-2': $vuetify.breakpoint.mdAndUp}"
                           large
                           v-model="userSelected"
                           :items="users"
                        />

                        <v-btn 
                           dark
                           color="teal"
                           class="mt-2 text-none"
                           :class="{'ml-4': $vuetify.breakpoint.mdAndUp}"
                           style="min-width:80px;"
                           @click="applyFilter(1)">
                           Lịch sử chi tiết
                        </v-btn>

                        <v-btn 
                           dark
                           color="teal"
                           class="mt-2 ml-2 text-none"
                           style="min-width:80px;"
                           @click="applyFilter(2)">
                           Báo cáo chấm công
                        </v-btn>
                     </v-card-title>
                  </v-card>

                  <v-progress-linear
                     class="mt-1"
                     v-if="numOfCall>0"
                     indeterminate
                     color="teal darken-2"
                  ></v-progress-linear>
               </v-col>
            </v-row>
            
            <!-- floor data -->
            <v-row v-if="showHistory"
               style="" class="ma-0 pa-0 pa-1">
               <!-- List of floors -->
               <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                  class="pa-0">
                  <v-row style="" class="ma-0 pa-0 pa-1">
                     <v-col cols="12" class="px-1 py-1">
                        <div flat 
                           class="pa-0" 
                           style="border-bottom:1px solid teal;">
                           <v-btn v-for="(floor, index) in floors" :key="index"
                              tile text
                              :color="activeFloor==index?'teal':'#AAAAAA'"
                              @click="activeFloor=index"
                              class="text-none px-2">
                              <span style="font-size:15px;">
                                 {{floor.name}}
                              </span>
                           </v-btn>
                           <v-btn
                              tile text
                              :color="activeFloor==-1?'teal':'#AAAAAA'"
                              @click="activeFloor=-1"
                              class="text-none px-2">
                              <span style="font-size:15px;">
                                 Tất cả
                              </span>
                           </v-btn>
                        </div>
                        <div>
                           <v-progress-linear
                              v-if="loading"
                              indeterminate
                              color="teal darken-2"
                           ></v-progress-linear>
                        </div>
                     </v-col>
                  </v-row>

                  <v-row v-if="floors"
                     class="px-6 pa-0">
                     <template v-for="(floor, index) in floors" >
                        <v-col 
                           :key="index"
                           v-if="index==activeFloor"
                           cols="12"
                           class="pa-0 pt-1">
                           <door-attds  :attds="floor.attds" />
                        </v-col>
                     </template>
                     <v-col 
                        v-if="activeFloor==-1"
                        cols="12"
                        class="pa-0 pt-1">
                        <door-attds  :attds="allAttds" />
                     </v-col>
                  </v-row>
               </v-col>
            </v-row>

            <!-- bao cao cham chong -->
            <v-row v-if="showReport"
               class="">
               <v-col cols="12" 
                  class="pa-6">
                  <report-attds 
                     :index="reportAttdIndex" 
                     :users="reportUsers" 
                     :dateRange="range" />
               </v-col>
            </v-row>

         </v-container>
      </div>
   </div>
</template>

<script>
export default {
   data () {
      return {
         allows: ['timekeeper-manager','admin'],
         loading: false,
         showReport: false,
         showHistory: false,
         userSelected: [],
         users:[],
         allAttds: [],
         pageCount: 0,
         usersPage: 1,
         usersHeader: [
            {
               text: 'Mã NV',
               align: 'start',
               sortable: false,
               value: 'mcvId',
               // value: 'user_code',
               width: '70px',
            },
            { text: 'Họ và Tên', value: 'fullname' },
         ],
         departments: [],
         socketAskKey: new Date().getTime(),
         activeFloor: 0,
         floors: require('@/mkdata/floors.js').floors,
         menuDatePicker: false,
         rangeMode: 'day',
         range: {
            start: this.$moment().startOf('day').toDate(),
            end: this.$moment().endOf('day').toDate(),
         },
         modelConfig: {
            start: {
               timeAdjust: '00:00:00',
            },
            end: {
               timeAdjust: '23:59:59',
            },
         },
         reportUsers: [],
         attds: [],
         reportAttdIndex: 0,
         numOfCall: 0
      }
   },
   sockets: {
      callHTTPRequestResult: function (payload) {
         console.log("callHTTPRequestResult")
         this.loading = false
         let self = this
         if(payload && payload.askKey == this.socketAskKey) {
            if(payload.result && payload.result.status=="OK"){
               // console.log(payload.result.content)
               //if(payload.result.content.api == "getAllAttd"){
                  /*
                     customer_id: "mcv-2021-r1-env"
                     _id: "60169a5f34b93f1cfc3ab702"
                     key: "192.168.1.101-6683-8888-1612092350000"
                     devUserId: "8888"
                     sysUserId: ""
                     doorName: "Tret"
                     zoneName: "Tret"
                     ip: "192.168.1.101"
                     date: "2021-01-31T11:25:50.000Z"
                  */
                  let attds = payload.result.content
                  this.allAttds = []

                  attds.forEach((attd) => {
                     if(this.users && this.users.length>0){
                        let matchUser = this.users.find(sysUser => sysUser.mcvId == attd.devUserId)
                        if(matchUser){
                           attd.name = matchUser.fullname
                           attd.match = true
                         } else {
                           attd.match = false
                           // console.log(u)
                        }
                        attd.attTime = self.$moment(attd.date).format("HH:mm:ss DD/MM/YYYY")
                     }
                  })

                  this.floors.forEach(floor => {
                     floor.doors.forEach(door => {
                        let dAttds = attds.filter(attd => attd.ip == door.ip && attd.match)
                        dAttds.forEach(attd => {
                           attd.floorName = floor.name
                           attd.doorName = door.name
                        })
                        // door.attds = door.attds.concat(dAttds)
                        floor.attds = floor.attds.concat(dAttds)
                        
                     })
                     floor.attds.sort(function(a,b){
                        return new Date(a.date) - new Date(b.date);
                     });

                     this.allAttds = this.allAttds.concat(floor.attds)
                     this.allAttds.sort(function(a,b){
                        return new Date(a.date) - new Date(b.date);
                     });
                  })



                  this.reportUsers.forEach(user => {
                     user.attds = user.attds.concat(attds.filter(attd => attd.devUserId == user.mcvId))
                  })

                  this.reportAttdIndex++
                  
               //}
               this.$forceUpdate()
            } else {
               console.log(payload.result)
            }
         }
         this.numOfCall--
         console.log(this.numOfCall)
      }
   },
   methods: {
      pickdate (type) {
         const datepicker = this.$refs.datepicker;
         
         if (type == 'today') {
            this.rangeMode = 'day'
            this.range = {
               start: this.$moment().startOf('day').toDate(),
               end: this.$moment().endOf('day').toDate()
            }
         }
         if (type == 'yesterday') {
            this.rangeMode = 'day'
            this.range = {
               start: this.$moment().add(-1, 'd').startOf('day').toDate(),
               end: this.$moment().add(-1, 'd').endOf('day').toDate()
            }
         }
         if (type == 'this-week') {
            this.rangeMode = 'week'
            this.range = {
               start: this.$moment().startOf('week').toDate(),
               end: this.$moment().endOf('week').toDate()
            }
         }

         if (type == 'last-week') {
            this.rangeMode = 'week'
            this.range = {
               start: this.$moment().startOf('week').add(-7, 'd').toDate(),
               end: this.$moment().endOf('week').add(-7, 'd').toDate()
            }
         }

         if (type == 'this-month') {
            this.rangeMode = 'month'
            this.range = {
               start: this.$moment().startOf('month').toDate(),
               end: this.$moment().endOf('month').toDate()
            }
         }

         if (type == 'last-month') {
            this.rangeMode = 'month'
            this.range = {
               start: this.$moment().add(-1,'months').startOf('month').toDate(),
               end: this.$moment().add(-1,'months').endOf('month').toDate()
            }
            
         }

         if (type == 'last-7-days') {
            this.rangeMode = '7day'
            this.range = {
               start: this.$moment().add(-7,'d').toDate(),
               end: this.$moment().toDate()
            }
         }

         if (type == 'last-30-days') {
            this.rangeMode = '30days'
            this.range = {
               start: this.$moment().add(-30,'d').toDate(),
               end: this.$moment().toDate()
            }
         }

         this.menuDatePicker = false
         datepicker.move(this.range.start)

         this.$forceUpdate()
      },
      applyFilter (type) {
         this.showHistory = type==1
         this.showReport = type==2
         this.fetchAttdForFloor()
      },
      convertMaNVToCompanyId(code){
         if(code && code.length>0){
            return Number(code.replace("NV", ""))
         }
         return -1
      },
      fetchUsers() {
         return new Promise((resolve) => {
            let self = this;
            let pathFetchDataUsers =
            self.$root.apiUser + "/users/list/" + self.$root.apiUserCustomId;
            let pathFetchDataDepartments =
            self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId;

            self.loading = true;
            Promise.all([
            self.axios.get(pathFetchDataUsers),
            self.axios.get(pathFetchDataDepartments),
            ])
            .then((responseArray) => {
               // console.log("Promise ALL",responseArray );
               if (responseArray[0].data.status == "OK") {
                  self.users = responseArray[0].data.content.items;
                  self.users.forEach(u => {
                     u.mcvId = this.convertMaNVToCompanyId(u.user_code)
                     u.id = u.mcviId
                  })

                  self.users = self.users.sort(function(a, b) {
                     return Number(a.mcvId) - Number(b.mcvId)
                  });
                  // console.log(self.users)
               }
               if (responseArray[1].data.status == "OK") {
                  self.departments = responseArray[1].data.content.items;
                  // console.log(self.departments)
               }
            })
            .catch((err) => {
               console.log(err);
            })
            .finally(() => {
               // self.processTotalReport();
               self.loading = false;
               resolve()
            });
         })
      },
      fetchAttdForFloor () {
         this.floors.forEach(floor => {
            floor.attds = []
            floor.doors.forEach(door => {
               door.attds = []
            })
         })

         this.allAttds = []

         console.log("User selected " + this.userSelected.length)

         if(this.userSelected.length<=0) return

         this.reportUsers = []
         this.userSelected.forEach(user => {
            this.reportUsers.push({
               user_code: user.user_code,
               fullname: user.fullname,
               department: user.department,
               mcvId: user.mcvId,
               attds: [],
               numOfDate: 0
            })
         })

         let userMcvIds = this.userSelected.map(u => ""+u.mcvId)

         let momentStart = this.$moment(this.range.start)
         let momentEnd  = this.$moment(this.range.end)

         let numOfDays = momentEnd.diff(momentStart, 'days') + 1
         this.numOfCall = 0
         let self = this
         setTimeout(function(){
            self.numOfCall = 0
         }, 20000)
         
         for(let i=0; i<numOfDays; i++){
            this.numOfCall++
            this.callApiOverOTA(
               "POST",
               this.$root.apiZK + "zk/adtendanceFilter",
               {  
                  customer_id :this.$root.apiUserCustomId,
                  // ips: ips,
                  devUserIds: userMcvIds,
                  fromDate: momentStart.clone().add(i, 'd').toDate(),
                  toDate: momentStart.clone().add(i+1, 'd').toDate(),
               }
            )
         }
         
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
         this.loading = true
      }
   },
   mounted () {
      this.$nextTick(() => {
         this.fetchUsers().then(() => {
            console.log('Fetch all user done!')
            this.fetchAttdForFloor()
         })
      })
   }
}
</script>

<style lang="">
   
</style>